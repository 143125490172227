<template>
  <div class="card p-shadow-6">
    <h1>Divulgações</h1>
    <ConfirmDialog />
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />

    <div class="p-col-12 p-md-3 p-mt-1">
      <Button
        label="Nova Comunicação"
        icon="pi pi-plus"
        class="p-button-success p-button-outlined"
        @click="viewDisclosure(0)"
      />
    </div>
    <DataTable
      :value="disclosureList"
      :filters.sync="filters"
      filterDisplay="row"
      :loading="loading"
      :paginator="true"
      :autoLayout="true"
      :rows="20"
      class="p-datatable-sm"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50]"
      currentPageReportTemplate="Mostrar {first} ao {last} de {totalRecords} divulgações"
      sortField="name"
      :sortOrder="1"
      responsiveLayout="scroll"
    >
      <template #empty> Nada a mostrar. </template>
      <template #loading> A carregar. Por favor Aguarde... </template>

      <Column
        field="responsible"
        header="Responsável"
        sortable
        filterField="responsible"
      >
        <template #body="slotProps">
          <span class="p-column-title">Nome:</span>
          {{ slotProps.data.responsible }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column field="title" header="Título" sortable filterField="title">
        <template #body="slotProps">
          <span class="p-column-title">Título :</span>
          {{ slotProps.data.title }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>

      <Column
        field="external_disclosure"
        header="Divulgação Externa"
        sortable
        filterField="external_disclosure"
        dataType="boolean"
      >
        <template #body="slotProps">
          <span class="p-column-title">Divulgação Externa:</span>
          {{ slotProps.data.external_disclosure ? "Sim" : "Não" }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            @input="filterCallback()"
            :options="[
              { value: 0, label: 'Não' },
              { value: 1, label: 'Sim' },
            ]"
            optionValue="value"
            optionLabel="label"
            class="p-column-filter"
            :showClear="true"
          >
          </Dropdown>
        </template>
      </Column>

      <Column
        field="internal_disclosure"
        header="Divulgação Interna"
        sortable
        filterField="internal_disclosure"
        dataType="boolean"
      >
        <template #body="slotProps">
          <span class="p-column-title">Divulgação Interna:</span>
          {{ slotProps.data.internal_disclosure ? "Sim" : "Não" }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            @input="filterCallback()"
            :options="[
              { value: 0, label: 'Não' },
              { value: 1, label: 'Sim' },
            ]"
            optionValue="value"
            optionLabel="label"
            class="p-column-filter"
            :showClear="true"
          >
          </Dropdown>
        </template>
      </Column>
      <Column
        field="date_external_disclosure_at"
        header="Divulgação Externa em"
        sortable
        filterField="date_external_disclosure_at"
        dataType="date"
      >
        <template #body="slotProps">
          <span class="p-column-title">Divulgação Externa em:</span>
          {{ slotProps.data.external_disclosure_at }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <v-date-picker
            v-model="filterModel.value"
            @input="filterCallback()"
            :masks="{
              input: 'YYYY-MM-DD',
            }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="p-column-filter p-inputtext p-component"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </v-date-picker>
        </template>
      </Column>
      <Column
        field="date_internal_disclosure_at"
        header="Divulgação Interna em"
        sortable
        filterField="date_internal_disclosure_at"
        dataType="date"
      >
        <template #body="slotProps">
          <span class="p-column-title">Divulgação Interna em:</span>
          {{ slotProps.data.internal_disclosure_at }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <v-date-picker
            v-model="filterModel.value"
            @input="filterCallback()"
            :masks="{
              input: 'YYYY-MM-DD',
            }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="p-column-filter p-inputtext p-component"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </v-date-picker>
        </template>
      </Column>

      <Column>
        <template #body="slotProps">
          <Button
            v-tooltip="'Edita/Ver Divulgação'"
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success p-button-outlined"
            @click="viewDisclosure(slotProps.data.id)"
          />
          <Button
            v-if="
              slotProps.data.internal_disclosure == 1 &&
              (!slotProps.data.internal_disclosure_at ||
                slotProps.data.internal_disclosure_at == '0000-00-00 00:00:00')
            "
            v-tooltip="'Divulgar Internamente'"
            icon="pi pi-external-link"
            class="p-button-rounded p-button-success p-button-outlined rotate p-ml-1"
            @click="setPublishDate(slotProps.data, 'internal')"
          />
          <Button
            v-if="
              slotProps.data.external_disclosure == 1 &&
              (!slotProps.data.external_disclosure_at ||
                slotProps.data.external_disclosure_at == '0000-00-00 00:00:00')
            "
            v-tooltip="'Divulgar Externamente'"
            icon="pi pi-external-link"
            class="p-button-rounded p-button-success p-button-outlined p-ml-1"
            @click="setPublishDate(slotProps.data, 'external')"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import disclosureservice from "../services/disclosure.service";
import { FilterMatchMode } from "primevue/api/";

export default {
  name: "Disclosure",
  data() {
    return {
      loading: true,
      disclosureList: [],
      filters: {
        responsible: { value: null, matchMode: FilterMatchMode.CONTAINS },
        title: { value: null, matchMode: FilterMatchMode.CONTAINS },
        external_disclosure: { value: null, matchMode: FilterMatchMode.EQUALS },
        internal_disclosure: { value: null, matchMode: FilterMatchMode.EQUALS },
        date_external_disclosure_at: {
          value: null,
          matchMode: FilterMatchMode.DATE_IS,
        },
        date_internal_disclosure_at: {
          value: null,
          matchMode: FilterMatchMode.DATE_IS,
        },
      },
    };
  },
  created() {
    this.loading = true;
    this.getAllDisclosure();
  },
  methods: {
    getAllDisclosure() {
      return disclosureservice.getAllDisclosure().then((response) => {
        response = response.map((v) => ({
          ...v,
          date_external_disclosure_at: new Date(v.external_disclosure_at),
          date_internal_disclosure_at: new Date(v.internal_disclosure_at),
        }));
        this.loading = false;
        return (this.disclosureList = response);
      });
    },
    viewDisclosure(viewDisclosureId) {
      this.$router.push(`/disclosure-view/${viewDisclosureId}`);
    },
    setPublishDate(disclosure, type) {
      let header = "";
      let message = "";
      if (type == "internal") {
        header = "Publicar Internamente";
        message = `Tem a certeza que pretende publicar na Intranet esta comunicação ${disclosure.title}?`;
      } else if (type == "external") {
        header = "Publicar Externamente";
        message = `Já publicou a comunicação ${disclosure.title} na newsletter?`;
      }
      this.$confirm.require({
        header: header,
        message: message,
        icon: "pi pi-question-circle",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-text p-button-success p-button p-component",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-text p-button-danger p-button p-component",
        accept: () => {
          disclosureservice
            .setPublishDate(disclosure.id, { publish_type: type })
            .then((response) => {
              if (!response) {
                return this.$toast.add({
                  severity: "error",
                  summary: "Erro ao inserir a data da publicação",
                  detail: "",
                  life: 3000,
                });
              }

              if (type == "internal") {
                disclosure.internal_disclosure_at = response.published_at;
              }
              if (type == "external") {
                disclosure.external_disclosure_at = response.published_at;
              }

              return this.$toast.add({
                severity: "success",
                summary: "Data da publicação inserida com sucesso",
                detail: "",
                life: 3000,
              });
            });
        },
      });
    },
  },
};
</script>
<style scoped lang="scss">
td {
  width: 1px;
  white-space: nowrap;
}

.p-column-filter {
  width: 100%;
}
.rotate {
  transform: rotate(180deg);
}
</style>
